import { Td } from '@chakra-ui/react'

type TableCellProps = {
  cell: any
  index: number
}

const TableCell = ({ cell, index }: TableCellProps) => {
  const { isActionColumn, isNumeric, maxWidth, minWidth, width } = cell.column
  const fontWeight = index === 0 ? 'semibold' : 'normal'
  // this is necessary because react-table hardcodes the maxWidth value,
  // so in order to check for a custom maxWidth value, we need to check
  // for the hardcoded value
  // ref: https://github.com/TanStack/table/blob/33169d3c2459215c5601b3ea062103c5ffda1548/packages/table-core/src/features/ColumnSizing.ts#L83
  const actualMaxWidth = isActionColumn
    ? '60px'
    : maxWidth === Number.MAX_SAFE_INTEGER
    ? '200px'
    : maxWidth

  return (
    <Td
      {...cell.getCellProps()}
      color='gray.700'
      fontSize='sm'
      fontWeight={fontWeight}
      height='40px'
      isNumeric={isNumeric}
      maxWidth={actualMaxWidth}
      minWidth={minWidth}
      overflow='hidden'
      paddingY={2}
      textOverflow='ellipsis'
      whiteSpace={'nowrap'}
      width={width}
    >
      {cell.render('Cell')}
    </Td>
  )
}

export default TableCell
