import {
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  IconButton,
  InputRightElement,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { BiSearch, BiXCircle } from 'react-icons/bi'

type SearchInputProps = {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  isDisabled?: boolean
}

const SearchInput = ({
  value,
  onChange,
  placeholder,
  isDisabled,
}: SearchInputProps) => {
  const errorDebounceRef = useRef<NodeJS.Timeout>()
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (errorDebounceRef.current) clearTimeout(errorDebounceRef.current)
    if (!!value && value.length < 3) {
      errorDebounceRef.current = setTimeout(() => {
        setHasError(true)
      }, 1000)
    } else {
      setHasError(false)
    }
  }, [value])

  return (
    <FormControl isInvalid={hasError}>
      <InputGroup
        borderRadius='6px'
        borderColor='#E2E8F0'
        backgroundColor='white'
      >
        <InputLeftElement
          pointerEvents='none'
          fontSize='1.2em'
          children={<Icon as={BiSearch} color='gray.400' />}
        />
        <Input
          value={value}
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeholder}
          isDisabled={isDisabled}
        />
        {value && (
          <InputRightElement
            children={
              <IconButton
                _active={{
                  backgroundColor: 'transparent',
                }}
                _hover={{
                  backgroundColor: 'transparent',
                }}
                aria-label='clear'
                backgroundColor='transparent'
                icon={<Icon as={BiXCircle} color='gray.400' />}
                onClick={() => onChange('')}
              />
            }
          />
        )}
      </InputGroup>

      <FormErrorMessage fontSize='xs' fontWeight='normal' marginTop={1}>
        Please enter at least 3 characters.
      </FormErrorMessage>
    </FormControl>
  )
}

export default SearchInput
