import { SectionItem } from '@/components/molecules'
import { Box, Accordion } from '@chakra-ui/react'
import { IconType } from 'react-icons'

type SubSectionItemProps = {
  dataTour?: string
  externalUrl?: string
  linkItems?: { name: string; route: string; dataTour?: string }[]
  name: string
  rootUrl?: string
  route?: string
  SectionIcon: IconType
}

type SidebarSubSectionProps = {
  isSidebarOpen: boolean
  onChangeAccordionItem: (openIndexes: any) => void
  openAccordionItems: number | undefined
  SubSectionItems: SubSectionItemProps[]
}

const SidebarSubSection = ({
  isSidebarOpen,
  onChangeAccordionItem,
  openAccordionItems,
  SubSectionItems,
}: SidebarSubSectionProps) => (
  <Box marginTop={5}>
    <Accordion
      variant='ghost'
      borderColor='transparent'
      marginTop={1}
      allowToggle
      onChange={onChangeAccordionItem}
      index={isSidebarOpen ? openAccordionItems : []}
    >
      {SubSectionItems.map((item) => (
        <SectionItem
          dataTour={item.dataTour}
          externalUrl={item.externalUrl}
          isSidebarOpen={isSidebarOpen}
          key={item.name}
          linkItems={item.linkItems}
          name={item.name}
          rootUrl={item.rootUrl}
          route={item.route}
          SectionIcon={item.SectionIcon}
        />
      ))}
    </Accordion>
  </Box>
)

export default SidebarSubSection
