import { Box, Text } from '@chakra-ui/react'
import { PopoverContentProps } from '@reactour/tour'
import VantageDocsAlert from '@/components/atoms/VantageDocsAlert'

type StepPopoverContentProps = PopoverContentProps & {
  docsMessage?: string
  docsRoute?: string
  text: string
  title: string
}

const StepPopoverContent = ({
  docsMessage,
  docsRoute,
  text,
  title,
}: StepPopoverContentProps) => (
  <Box>
    <Text color='black' fontSize='md' fontWeight='semibold'>
      {title}
    </Text>
    <Text marginTop={2} color='gray.600'>
      {text}
    </Text>
    {!!docsRoute && !!docsMessage && (
      <Box marginTop={4}>
        <VantageDocsAlert route={docsRoute} message={docsMessage} />
      </Box>
    )}
  </Box>
)

export default StepPopoverContent
