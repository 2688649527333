const SupportTicketEmptyStateIcon = () => (
  <svg
    width='108'
    height='86'
    viewBox='0 0 108 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M42.2812 85.1875C65.5808 85.1875 84.4688 66.2995 84.4688 43C84.4688 19.7005 65.5808 0.8125 42.2812 0.8125C18.9817 0.8125 0.09375 19.7005 0.09375 43C0.09375 66.2995 18.9817 85.1875 42.2812 85.1875Z'
      fill='#EEECFB'
    />
    <g filter='url(#filter0_d_1852_402)'>
      <path
        d='M23.1562 19.9375H99.0938C99.8397 19.9375 100.555 20.2338 101.082 20.7613C101.61 21.2887 101.906 22.0041 101.906 22.75V36.8125C101.906 37.5584 101.61 38.2738 101.082 38.8012C100.555 39.3287 99.8397 39.625 99.0938 39.625H23.1562C22.4103 39.625 21.695 39.3287 21.1675 38.8012C20.6401 38.2738 20.3438 37.5584 20.3438 36.8125V22.75C20.3438 22.0041 20.6401 21.2887 21.1675 20.7613C21.695 20.2338 22.4103 19.9375 23.1562 19.9375V19.9375Z'
        fill='white'
      />
    </g>
    <path
      d='M59.1562 24.4375H44.5312C43.5993 24.4375 42.8438 25.193 42.8438 26.125C42.8438 27.057 43.5993 27.8125 44.5312 27.8125H59.1562C60.0882 27.8125 60.8438 27.057 60.8438 26.125C60.8438 25.193 60.0882 24.4375 59.1562 24.4375Z'
      fill='#ABA0E9'
    />
    <path
      d='M69.2812 31.75H44.5312C43.5993 31.75 42.8438 32.5055 42.8438 33.4375C42.8438 34.3695 43.5993 35.125 44.5312 35.125H69.2812C70.2132 35.125 70.9688 34.3695 70.9688 33.4375C70.9688 32.5055 70.2132 31.75 69.2812 31.75Z'
      fill='#CDC6F1'
    />
    <path
      d='M38.3438 29.7812C38.3438 25.2767 34.6921 21.625 30.1875 21.625C25.6829 21.625 22.0312 25.2767 22.0312 29.7812C22.0312 34.2858 25.6829 37.9375 30.1875 37.9375C34.6921 37.9375 38.3438 34.2858 38.3438 29.7812Z'
      fill='#ABA0E9'
    />
    <path
      d='M30.8049 30.4492C30.761 30.5884 30.7372 30.8044 30.7335 31.0974H29.2504C29.2723 30.4785 29.3309 30.0519 29.4261 29.8175C29.5214 29.5795 29.7667 29.3066 30.1622 28.999L30.5632 28.6859C30.6951 28.587 30.8013 28.479 30.8818 28.3618C31.0283 28.1604 31.1016 27.9388 31.1016 27.6971C31.1016 27.4188 31.0192 27.1661 30.8544 26.9391C30.6932 26.7084 30.3966 26.593 29.9645 26.593C29.5397 26.593 29.2375 26.734 29.0581 27.016C28.8823 27.298 28.7944 27.5909 28.7944 27.8949H27.2069C27.2509 26.8512 27.6152 26.1115 28.3 25.6757C28.7322 25.3973 29.2632 25.2582 29.8931 25.2582C30.7207 25.2582 31.4073 25.4559 31.953 25.8514C32.5023 26.2469 32.777 26.8329 32.777 27.6093C32.777 28.0853 32.658 28.4863 32.4199 28.8123C32.2808 29.01 32.0134 29.2627 31.6179 29.5703L31.2279 29.8724C31.0155 30.0372 30.8745 30.2295 30.8049 30.4492ZM30.8489 33.4375H29.2119V31.85H30.8489V33.4375Z'
      fill='white'
    />
    <g filter='url(#filter1_d_1852_402)'>
      <path
        d='M90.6562 45.25H14.7188C13.1654 45.25 11.9062 46.5092 11.9062 48.0625V62.125C11.9062 63.6783 13.1654 64.9375 14.7188 64.9375H90.6562C92.2095 64.9375 93.4688 63.6783 93.4688 62.125V48.0625C93.4688 46.5092 92.2095 45.25 90.6562 45.25Z'
        fill='white'
      />
    </g>
    <path
      d='M50.7188 49.75H36.0938C35.1618 49.75 34.4062 50.5055 34.4062 51.4375C34.4062 52.3695 35.1618 53.125 36.0938 53.125H50.7188C51.6507 53.125 52.4062 52.3695 52.4062 51.4375C52.4062 50.5055 51.6507 49.75 50.7188 49.75Z'
      fill='#ABA0E9'
    />
    <path
      d='M60.8438 57.0625H36.0938C35.1618 57.0625 34.4062 57.818 34.4062 58.75C34.4062 59.682 35.1618 60.4375 36.0938 60.4375H60.8438C61.7757 60.4375 62.5312 59.682 62.5312 58.75C62.5312 57.818 61.7757 57.0625 60.8438 57.0625Z'
      fill='#CDC6F1'
    />
    <path
      d='M29.9062 55.0938C29.9062 50.5892 26.2546 46.9375 21.75 46.9375C17.2454 46.9375 13.5938 50.5892 13.5938 55.0938C13.5938 59.5983 17.2454 63.25 21.75 63.25C26.2546 63.25 29.9062 59.5983 29.9062 55.0938Z'
      fill='#ABA0E9'
    />
    <path
      d='M22.8168 56.066C22.7728 56.2052 22.749 56.4213 22.7454 56.7142H21.2622C21.2842 56.0953 21.3428 55.6687 21.438 55.4343C21.5332 55.1963 21.7786 54.9235 22.1741 54.6158L22.5751 54.3027C22.7069 54.2039 22.8131 54.0958 22.8937 53.9786C23.0402 53.7772 23.1134 53.5557 23.1134 53.314C23.1134 53.0356 23.031 52.783 22.8662 52.5559C22.7051 52.3252 22.4084 52.2098 21.9763 52.2098C21.5515 52.2098 21.2494 52.3508 21.0699 52.6328C20.8942 52.9148 20.8063 53.2078 20.8063 53.5117H19.2188C19.2627 52.468 19.6271 51.7283 20.3119 51.2925C20.744 51.0142 21.275 50.875 21.9049 50.875C22.7325 50.875 23.4192 51.0728 23.9648 51.4683C24.5142 51.8638 24.7888 52.4497 24.7888 53.2261C24.7888 53.7021 24.6698 54.1031 24.4318 54.4291C24.2926 54.6268 24.0253 54.8795 23.6298 55.1871L23.2397 55.4893C23.0273 55.6541 22.8864 55.8463 22.8168 56.066ZM22.8607 59.0543H21.2238V57.4668H22.8607V59.0543Z'
      fill='white'
    />
    <defs>
      <filter
        id='filter0_d_1852_402'
        x='14.3438'
        y='16.9375'
        width='93.5625'
        height='31.6875'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='3' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1852_402'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1852_402'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d_1852_402'
        x='5.90625'
        y='42.25'
        width='93.5625'
        height='31.6875'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='3' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1852_402'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1852_402'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)
export default SupportTicketEmptyStateIcon
