import { StepType } from '@reactour/tour'
import { PositionProps } from '@reactour/popover'
import { WelcomeWalkthroughInitialStep } from '@/components/atoms'
import { StepPopoverContent } from '@/components/molecules'

// to compensate the popover style, we need to adjust the position
const calculatePosition = (position: PositionProps) =>
  [position.left - 5, position.top + (position.bottom - position.top) + 5] as [
    number,
    number
  ]

const welcomeWalkthroughSteps: StepType[] = [
  {
    // no selector is used here since the popover is centered
    selector: 'none',
    position: 'center',
    content: WelcomeWalkthroughInitialStep,
    styles: {
      popover: (base) => ({
        ...base,
        borderRadius: '8px',
        minWidth: '600px',
        padding: '20px',
      }),
      // hide the mask and highlighted area
      maskArea: () => ({
        display: 'none',
      }),
      highlightedArea: () => ({
        display: 'none',
      }),
    },
  },
  {
    selector: '[data-tour="menu-header"]',
    // x: 20 as padding + 500 popover width, y: 65 as menu button height
    position: ({ windowWidth }) => [windowWidth - 520, 65],
    content: (contentProps) => {
      return (
        <StepPopoverContent
          {...contentProps}
          title='Managing Users and Organizations'
          text="Invite new users and manage your organization's settings under the Organization settings panel."
          docsMessage='to view the tutorial on Managing Users.'
          docsRoute='aaaaaaa'
        />
      )
    },
  },
  {
    selector: '[data-tour="sidebar-clusters"]',
    position: calculatePosition,
    content: (contentProps) => {
      return (
        <StepPopoverContent
          {...contentProps}
          title='Managing Clusters'
          text='Connect to clusters either on-premises or in the cloud through the Clusters section.'
          docsMessage='to view the tutorial on Creating a Cloud Cluster.'
          docsRoute='https://docs.vantagehpc.io/docs/getting-started/add-cluster'
        />
      )
    },
  },
  {
    selector: '[data-tour="sidebar-jobs"]',
    position: calculatePosition,
    content: (contentProps) => {
      return (
        <StepPopoverContent
          {...contentProps}
          title='Managing Jobs'
          text='Edit and submit jobs to any connected cluster from the Jobs section.'
          docsMessage='to view the tutorial on Managing Job Scripts.'
          docsRoute='https://docs.vantagehpc.io/docs/tutorials/write-a-job-script'
        />
      )
    },
  },
  {
    selector: '[data-tour="sidebar-support"]',
    position: calculatePosition,
    content: (contentProps) => {
      return (
        <StepPopoverContent
          {...contentProps}
          title='Getting Support'
          text='Get around-the-clock assistance with troubleshooting and platform support by visiting the Support section.'
          docsMessage=' to view the tutorial on Getting Help.'
          docsRoute='https://docs.vantagehpc.io/docs/how-to-guides/create-a-support-request'
        />
      )
    },
  },
]
export default welcomeWalkthroughSteps
