import { useRef, useEffect, useCallback, useMemo, useReducer } from 'react'

export type SortBy = Array<{ id: string; desc?: boolean }>

type UseTableControllersInitialParameters = {
  initialPageSize?: number
  initialPage?: number
  initialSortBy?: SortBy
}

enum TableControllerActionTypes {
  CLEAR_GLOBAL_FILTER_VALUE = 'CLEAR_GLOBAL_FILTER_VALUE',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_GLOBAL_FILTER_VALUE = 'SET_GLOBAL_FILTER_VALUE',
  SET_PAGE_SIZE = 'SET_PAGE_SIZE',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  SET_SORT_BY = 'SET_SORT_BY',
}

type TableControllerState = {
  currentPage: number
  globalFilterValue: string
  pageSize: number
  searchValue: string
  sortBy: SortBy
}

type TableControllerAction =
  | {
      type: TableControllerActionTypes.CLEAR_GLOBAL_FILTER_VALUE
    }
  | {
      type:
        | TableControllerActionTypes.SET_CURRENT_PAGE
        | TableControllerActionTypes.SET_PAGE_SIZE
      payload: number
    }
  | {
      type:
        | TableControllerActionTypes.SET_GLOBAL_FILTER_VALUE
        | TableControllerActionTypes.SET_SEARCH_VALUE
      payload: string
    }
  | {
      type: TableControllerActionTypes.SET_SORT_BY
      payload: SortBy
    }

const tableControllerReducer = (
  state: TableControllerState,
  action: TableControllerAction
) => {
  switch (action.type) {
    case TableControllerActionTypes.CLEAR_GLOBAL_FILTER_VALUE:
      return {
        ...state,
        globalFilterValue: '',
        currentPage: 0,
      }
    case TableControllerActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case TableControllerActionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
        currentPage: 0,
      }
    case TableControllerActionTypes.SET_GLOBAL_FILTER_VALUE:
      return {
        ...state,
        globalFilterValue: action.payload,
        currentPage: 0,
      }
    case TableControllerActionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      }
    case TableControllerActionTypes.SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      }
  }
}

const useTableControllers = (
  initialParameters?: UseTableControllersInitialParameters
) => {
  // ref to save the debounce timeout id between renders
  const debounceRef = useRef<NodeJS.Timeout>()

  const initialReducerValues = useMemo(() => {
    const { initialPage, initialPageSize, initialSortBy } =
      initialParameters || {}
    return {
      currentPage: initialPage ?? 0,
      globalFilterValue: '',
      pageSize: initialPageSize ?? 50,
      searchValue: '',
      sortBy: initialSortBy ?? [],
    }
  }, [initialParameters])

  const [
    { currentPage, globalFilterValue, pageSize, searchValue, sortBy },
    dispatch,
  ] = useReducer(tableControllerReducer, initialReducerValues)

  const onGoToPage = useCallback((page: number) => {
    dispatch({
      type: TableControllerActionTypes.SET_CURRENT_PAGE,
      payload: page,
    })
  }, [])

  const onChangePageSize = useCallback((pageSize: number) => {
    dispatch({
      type: TableControllerActionTypes.SET_PAGE_SIZE,
      payload: pageSize,
    })
  }, [])

  const onChangeSortBy = useCallback((sortBy: SortBy) => {
    dispatch({
      type: TableControllerActionTypes.SET_SORT_BY,
      payload: sortBy,
    })
  }, [])

  const onChangeSearch = useCallback(
    (value: string) =>
      dispatch({
        type: TableControllerActionTypes.SET_SEARCH_VALUE,
        payload: value,
      }),
    []
  )

  // debounce effect for search
  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current)
    // instant clear search value when user delete all characters
    if (!searchValue) {
      dispatch({ type: TableControllerActionTypes.CLEAR_GLOBAL_FILTER_VALUE })
    }
    debounceRef.current = setTimeout(() => {
      // waits for user to type at least 3 characters before searching
      if (searchValue.length > 2) {
        dispatch({
          type: TableControllerActionTypes.SET_GLOBAL_FILTER_VALUE,
          payload: searchValue,
        })
      }
    }, 300)
  }, [searchValue])

  return {
    currentPage,
    pageSize,
    globalFilterValue,
    sortBy,
    searchValue,
    onGoToPage,
    onChangePageSize,
    onChangeSortBy,
    onChangeSearch,
  }
}

export default useTableControllers
