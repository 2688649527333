const LicenseUsageEmptyStateIcon = () => (
  <svg
    width='86'
    height='86'
    viewBox='0 0 86 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M43 85.1875C66.2995 85.1875 85.1875 66.2995 85.1875 43C85.1875 19.7005 66.2995 0.8125 43 0.8125C19.7005 0.8125 0.8125 19.7005 0.8125 43C0.8125 66.2995 19.7005 85.1875 43 85.1875Z'
      fill='#EEECFB'
    />
    <mask
      id='mask0_1852_409'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='86'
      height='86'
    >
      <path
        d='M43 85.1875C66.2995 85.1875 85.1875 66.2995 85.1875 43C85.1875 19.7005 66.2995 0.8125 43 0.8125C19.7005 0.8125 0.8125 19.7005 0.8125 43C0.8125 66.2995 19.7005 85.1875 43 85.1875Z'
        fill='#EEECFB'
      />
    </mask>
    <g mask='url(#mask0_1852_409)'>
      <path
        d='M68.3125 85.1875H17.6875V30.625C20.0736 30.6223 22.3613 29.6733 24.0485 27.986C25.7357 26.2988 26.6848 24.0111 26.6875 21.625H59.3125C59.31 22.807 59.5419 23.9778 59.9948 25.0696C60.4477 26.1614 61.1127 27.1526 61.9512 27.9857C62.7843 28.8245 63.7755 29.4896 64.8674 29.9427C65.9594 30.3957 67.1303 30.6276 68.3125 30.625V85.1875Z'
        fill='white'
      />
    </g>
    <path
      d='M43 58.1875C50.4558 58.1875 56.5 52.1433 56.5 44.6875C56.5 37.2317 50.4558 31.1875 43 31.1875C35.5442 31.1875 29.5 37.2317 29.5 44.6875C29.5 52.1433 35.5442 58.1875 43 58.1875Z'
      fill='#ABA0E9'
    />
    <path
      d='M50.3125 63.25H35.6875C34.7555 63.25 34 64.0055 34 64.9375C34 65.8695 34.7555 66.625 35.6875 66.625H50.3125C51.2445 66.625 52 65.8695 52 64.9375C52 64.0055 51.2445 63.25 50.3125 63.25Z'
      fill='#CDC6F1'
    />
    <path
      d='M55.375 70H30.625C29.693 70 28.9375 70.7555 28.9375 71.6875C28.9375 72.6195 29.693 73.375 30.625 73.375H55.375C56.307 73.375 57.0625 72.6195 57.0625 71.6875C57.0625 70.7555 56.307 70 55.375 70Z'
      fill='#CDC6F1'
    />
    <path
      d='M37.0938 53.125H48.9062C49.8369 53.125 50.5938 52.3682 50.5938 51.4375V38.7812C50.5938 37.8506 49.8369 37.0938 48.9062 37.0938H47.2188C47.2188 36.6272 46.8416 36.25 46.375 36.25H39.625C39.1584 36.25 38.7812 36.6272 38.7812 37.0938H37.0938C36.1631 37.0938 35.4062 37.8506 35.4062 38.7812V51.4375C35.4062 52.3682 36.1631 53.125 37.0938 53.125ZM37.0938 38.7812H38.7812V40.4688H47.2188V38.7812H48.9062V51.4375H37.0938V38.7812Z'
      fill='white'
    />
    <path
      d='M42.1562 46.0257L40.6434 44.5128L39.4503 45.7059L42.1562 48.4118L46.5496 44.0184L45.3566 42.8253L42.1562 46.0257Z'
      fill='white'
    />
  </svg>
)
export default LicenseUsageEmptyStateIcon
