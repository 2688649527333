const JobSubmissionEmptyStateIcon = () => (
  <svg
    width='118'
    height='83'
    viewBox='0 0 118 83'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M93.875 0.625H26.375C23.2684 0.625 20.75 3.1434 20.75 6.25V73.75C20.75 76.8566 23.2684 79.375 26.375 79.375H93.875C96.9816 79.375 99.5 76.8566 99.5 73.75V6.25C99.5 3.1434 96.9816 0.625 93.875 0.625Z'
      fill='#EEECFB'
    />
    <g filter='url(#filter0_d_2447_4017)'>
      <path
        d='M33.125 29.875H109.062C109.808 29.875 110.524 30.1713 111.051 30.6988C111.579 31.2262 111.875 31.9416 111.875 32.6875V46.75C111.875 47.4959 111.579 48.2113 111.051 48.7387C110.524 49.2662 109.808 49.5625 109.062 49.5625H33.125C32.3791 49.5625 31.6637 49.2662 31.1363 48.7387C30.6088 48.2113 30.3125 47.4959 30.3125 46.75V32.6875C30.3125 31.9416 30.6088 31.2262 31.1363 30.6988C31.6637 30.1713 32.3791 29.875 33.125 29.875V29.875Z'
        fill='white'
      />
    </g>
    <path
      d='M74.75 34.375H60.125C59.193 34.375 58.4375 35.1305 58.4375 36.0625C58.4375 36.9945 59.193 37.75 60.125 37.75H74.75C75.682 37.75 76.4375 36.9945 76.4375 36.0625C76.4375 35.1305 75.682 34.375 74.75 34.375Z'
      fill='#ABA0E9'
    />
    <path
      d='M84.875 41.6875H60.125C59.193 41.6875 58.4375 42.443 58.4375 43.375C58.4375 44.307 59.193 45.0625 60.125 45.0625H84.875C85.807 45.0625 86.5625 44.307 86.5625 43.375C86.5625 42.443 85.807 41.6875 84.875 41.6875Z'
      fill='#CDC6F1'
    />
    <path
      d='M47.4688 45.0625C50.42 45.0625 52.8125 42.67 52.8125 39.7188C52.8125 36.7675 50.42 34.375 47.4688 34.375C44.5175 34.375 42.125 36.7675 42.125 39.7188C42.125 42.67 44.5175 45.0625 47.4688 45.0625Z'
      fill='#ABA0E9'
    />
    <g clipPath='url(#clip0_2447_4017)'>
      <path
        d='M48.3828 36.6758H46.5547V38.8046H44.4219V40.6328H46.5547V42.7616H48.3828V40.6328H50.5156V38.8046H48.3828V36.6758Z'
        fill='white'
      />
    </g>
    <g filter='url(#filter1_d_2447_4017)'>
      <path
        d='M8.9375 54.0625H84.875C85.6209 54.0625 86.3363 54.3588 86.8637 54.8863C87.3912 55.4137 87.6875 56.1291 87.6875 56.875V70.9375C87.6875 71.6834 87.3912 72.3988 86.8637 72.9262C86.3363 73.4537 85.6209 73.75 84.875 73.75H8.9375C8.19158 73.75 7.47621 73.4537 6.94876 72.9262C6.42132 72.3988 6.125 71.6834 6.125 70.9375V56.875C6.125 56.1291 6.42132 55.4137 6.94876 54.8863C7.47621 54.3588 8.19158 54.0625 8.9375 54.0625V54.0625Z'
        fill='white'
      />
    </g>
    <path
      d='M50.5625 58.5625H35.9375C35.0055 58.5625 34.25 59.318 34.25 60.25C34.25 61.182 35.0055 61.9375 35.9375 61.9375H50.5625C51.4945 61.9375 52.25 61.182 52.25 60.25C52.25 59.318 51.4945 58.5625 50.5625 58.5625Z'
      fill='#ABA0E9'
    />
    <path
      d='M60.6875 65.875H35.9375C35.0055 65.875 34.25 66.6305 34.25 67.5625C34.25 68.4945 35.0055 69.25 35.9375 69.25H60.6875C61.6195 69.25 62.375 68.4945 62.375 67.5625C62.375 66.6305 61.6195 65.875 60.6875 65.875Z'
      fill='#CDC6F1'
    />
    <path
      d='M15.9688 69.25C18.92 69.25 21.3125 66.8575 21.3125 63.9062C21.3125 60.955 18.92 58.5625 15.9688 58.5625C13.0175 58.5625 10.625 60.955 10.625 63.9062C10.625 66.8575 13.0175 69.25 15.9688 69.25Z'
      fill='#ABA0E9'
    />
    <g clipPath='url(#clip1_2447_4017)'>
      <path
        d='M16.8828 60.8633H15.0547V62.9921H12.9219V64.8203H15.0547V66.9491H16.8828V64.8203H19.0156V62.9921H16.8828V60.8633Z'
        fill='white'
      />
    </g>
    <g filter='url(#filter2_d_2447_4017)'>
      <path
        d='M84.875 5.6875H8.9375C7.3842 5.6875 6.125 6.9467 6.125 8.5V22.5625C6.125 24.1158 7.3842 25.375 8.9375 25.375H84.875C86.4283 25.375 87.6875 24.1158 87.6875 22.5625V8.5C87.6875 6.9467 86.4283 5.6875 84.875 5.6875Z'
        fill='white'
      />
    </g>
    <path
      d='M49.4375 10.1875H34.8125C33.8805 10.1875 33.125 10.943 33.125 11.875C33.125 12.807 33.8805 13.5625 34.8125 13.5625H49.4375C50.3695 13.5625 51.125 12.807 51.125 11.875C51.125 10.943 50.3695 10.1875 49.4375 10.1875Z'
      fill='#ABA0E9'
    />
    <path
      d='M59.5625 17.5H34.8125C33.8805 17.5 33.125 18.2555 33.125 19.1875C33.125 20.1195 33.8805 20.875 34.8125 20.875H59.5625C60.4945 20.875 61.25 20.1195 61.25 19.1875C61.25 18.2555 60.4945 17.5 59.5625 17.5Z'
      fill='#CDC6F1'
    />
    <path
      d='M23.2812 20.875C26.2325 20.875 28.625 18.4825 28.625 15.5312C28.625 12.58 26.2325 10.1875 23.2812 10.1875C20.33 10.1875 17.9375 12.58 17.9375 15.5312C17.9375 18.4825 20.33 20.875 23.2812 20.875Z'
      fill='#ABA0E9'
    />
    <g clipPath='url(#clip2_2447_4017)'>
      <path
        d='M24.1953 12.4883H22.3672V14.6171H20.2344V16.4453H22.3672V18.5741H24.1953V16.4453H26.3281V14.6171H24.1953V12.4883Z'
        fill='white'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_2447_4017'
        x='24.3125'
        y='26.875'
        width='93.5625'
        height='31.6875'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='3' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_2447_4017'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_2447_4017'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d_2447_4017'
        x='0.125'
        y='51.0625'
        width='93.5625'
        height='31.6875'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='3' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_2447_4017'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_2447_4017'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_d_2447_4017'
        x='0.125'
        y='2.6875'
        width='93.5625'
        height='31.6875'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='3' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_2447_4017'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_2447_4017'
          result='shape'
        />
      </filter>
      <clipPath id='clip0_2447_4017'>
        <rect
          width='7.3125'
          height='7.3125'
          fill='white'
          transform='translate(43.8125 36.0625)'
        />
      </clipPath>
      <clipPath id='clip1_2447_4017'>
        <rect
          width='7.3125'
          height='7.3125'
          fill='white'
          transform='translate(12.3125 60.25)'
        />
      </clipPath>
      <clipPath id='clip2_2447_4017'>
        <rect
          width='7.3125'
          height='7.3125'
          fill='white'
          transform='translate(19.625 11.875)'
        />
      </clipPath>
    </defs>
  </svg>
)
export default JobSubmissionEmptyStateIcon
