import { TourProps } from '@reactour/tour'
import getColorWithOpacity from '@/commons/helpers/getColorWithOpacity'

const tourProviderStyles: TourProps['styles'] = {
  popover: (base) => ({
    ...base,
    borderRadius: '8px',
    padding: '20px',
    minWidth: '500px',
  }),
  highlightedArea: (base) => ({
    ...base,
    display: 'block',
    height: base.height - 14,
    rx: 4,
    stroke: getColorWithOpacity('fluorescentBlue.500'),
    strokeWidth: 4,
    width: base.width - 16,
    x: base.x + 6,
    y: base.y + 7,
  }),
  maskArea: (base) => ({
    ...base,
    height: base.height - 14,
    width: base.width - 16,
    x: base.x + 6,
    y: base.y + 7,
  }),
}

export default tourProviderStyles
