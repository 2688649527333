import { useEffect } from 'react'
import { AuthProvider, AuthProviderProps } from 'react-oidc-context'
import { Provider } from 'react-redux'
import Hotjar from '@hotjar/browser'
import ReactGA from 'react-ga4'
import { TourProvider } from '@reactour/tour'

import { UserManagerClient } from '@/commons/helpers'
import {
  reactAppEnv,
  hotjarId,
  hotjarVersion,
  envGtag,
} from '@/commons/helpers/LocalEnvData'
import TourNavigation from '@/components/atoms/TourNavigation'
import Routes from '@/components/molecules/Routes'

import { store } from '@/store/store'
import tourProviderStyles from '@/theme/tourProviderStyles'
import welcomeWalkthroughSteps from '@/tourSteps/welcomeWalkthrough'

const oidcConfig: AuthProviderProps = {
  userManager: UserManagerClient,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}

const Guardian = () => {
  useEffect(() => {
    if (reactAppEnv !== 'dev') {
      if (!!hotjarId && !!hotjarVersion) {
        Hotjar.init(hotjarId, hotjarVersion)
      }
      if (!!envGtag) {
        ReactGA.initialize(envGtag)
      }
    }
  }, [])

  return (
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <TourProvider
          components={{ Navigation: TourNavigation }}
          // prevent the tour closing when clicking outside the popover
          onClickMask={() => null}
          showBadge={false}
          showCloseButton={false}
          steps={welcomeWalkthroughSteps}
          styles={tourProviderStyles}
        >
          <Routes />
        </TourProvider>
      </Provider>
    </AuthProvider>
  )
}

export default Guardian
