import { Box, chakra, UnorderedList, ListItem, Text } from '@chakra-ui/react'

const WelcomeWalkthroughInitialStep = () => (
  <Box>
    <Text color='black' fontSize='2xl' fontWeight='bold'>
      Welcome to Vantage!
    </Text>
    <Text color='gray.600' marginTop={2}>
      Welcome to Vantage, a supercharged engineering workbench for HPC that
      provides:
    </Text>
    <chakra.br />
    <UnorderedList paddingLeft={3} spacing={0} fontSize='sm' color='gray.600'>
      <ListItem>
        Cluster management for on-premise, cloud-based, and hybrid clusters
      </ListItem>
      <ListItem>Job script management, submission, and monitoring</ListItem>
      <ListItem>Access to 24/7 support right in the Vantage UI</ListItem>
    </UnorderedList>
    <chakra.br />
    <Text color='gray.600'>Learn more on this quick tour!</Text>
  </Box>
)

export default WelcomeWalkthroughInitialStep
