import { theme } from '@chakra-ui/react'
const colors = {
  ...theme.colors,
  licenseUsageItemBackground: '#FDFBFF',
  mainBackground: '#F3F6FA',
  navigationBackground: '#1A1440',
  navigationAccordionIcon: '#897CE1',
  tabsBackground: '#ECF1F6',
  tableEllipsis: '#484848',
  fluorescentBlue: {
    50: '#E9FFFF',
    100: '#D9FCFC',
    200: '#C1FFFF',
    300: '#9DF7F7',
    400: '#80F2F2',
    500: '#5CE9E9',
    600: '#45D0D0',
    700: '#29ABAB',
    800: '#147E7E',
    900: '#083F3F',
  },
  iris: {
    50: '#EEECFB',
    100: '#DDD9F5',
    200: '#CDC6F1',
    300: '#ABA0E9',
    400: '#7868DD',
    500: '#5642D6',
    600: '#4535AA',
    700: '#3C3095',
    800: '#2B216B',
    900: '#1A1440',
  },
  russianViolet: {
    50: '#E7E6EB',
    100: '#CECED5',
    200: '#B8B5C1',
    300: '#9E9CAC',
    400: '#6E6B83',
    500: '#3E395A',
    600: '#0E0831',
    700: '#090521',
    800: '#070418',
    900: '#04020E',
  },
}

export default colors
