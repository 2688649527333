import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  chakra,
  Link,
} from '@chakra-ui/react'
import { BiLibrary } from 'react-icons/bi'

type VantageDocsAlertProps = {
  route: string
  message: string
}

const VantageDocsAlert = ({ route, message }: VantageDocsAlertProps) => (
  <Alert status='info' backgroundColor='blue.50'>
    <AlertIcon as={BiLibrary} color='blue.500' boxSize='18px' />
    <Box>
      <AlertTitle
        fontSize='sm'
        fontStyle='italic'
        fontWeight='semibold'
        color='russianViolet.600'
      >
        Vantage docs
      </AlertTitle>
      <AlertDescription fontSize='sm'>
        <Link
          color='blue.500'
          href={route}
          isExternal
          fontWeight='semibold'
          textDecoration='underline'
          _hover={{ textDecoration: 'none' }}
        >
          Click here
        </Link>{' '}
        <chakra.span color='russianViolet.600'>{message}</chakra.span>
      </AlertDescription>
    </Box>
  </Alert>
)

export default VantageDocsAlert
